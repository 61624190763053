import React from 'react';
import { Helmet } from "react-helmet";
import AppRouter from "./router";
import Navbar from "./views/components/Navbar";
import Footer from "./views/components/Footer";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Movis.ng - Download Movies Online for Free</title>
        <meta name="description" content="Download and stream the latest movies and TV series for free on Movis.ng" />
        <link rel="canonical" href="https://movis.ng" />
      </Helmet>
      <Navbar />
      <main className="flex-grow">
        <AppRouter />
      </main>
      <Footer />
    </div>
  );
}

export default App;