import React from "react";

const Footer = () => {
  const navLinks = [
    { href: "/", label: "Home" },
    { href: "/movies", label: "Movies" },
    { href: "/series", label: "TV Series" },
    { href: "/dcma", label: "DMCA" },
    { href: "/contact", label: "Contact Us" },
  ];

  const socialLinks = [
    { href: "https://twitter.com/movisng", label: "Twitter", icon: "M11 4a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V11a7 7 0 0 0-7-7H11zm1.126 8.756h5.144l4.056 5.85 4.882-5.85h1.831l-5.922 7.101 7.301 10.143h-5.124l-4.502-6.332-5.353 6.332h-1.799l6.468-7.695-6.982-9.549zm2.739 1.472l10.053 14.59h2.169L17.14 14.228h-2.275z" },
    { href: "https://instagram.com/movis.ng", label: "Instagram", icon: "M16 3C8.83 3 3 8.83 3 16v18c0 7.17 5.83 13 13 13h18c7.17 0 13-5.83 13-13V16c0-7.17-5.83-13-13-13H16zm21 8c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm-12-1c6.07 0 11 4.93 11 11s-4.93 11-11 11-11-4.93-11-11 4.93-11 11-11zm0 2c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9z" },
    { href: "https://t.me/movisng", label: "Telegram", icon: "M25 2c12.703 0 23 10.297 23 23S37.703 48 25 48 2 37.703 2 25 12.297 2 25 2zm7.934 32.375c.423-1.298 2.405-14.234 2.65-16.783.074-.772-.17-1.285-.648-1.514-.578-.278-1.434-.139-2.427.219-1.362.491-18.774 7.884-19.78 8.312-.954.405-1.856.847-1.856 1.487 0 .45.267.703 1.003.966.766.273 2.695.858 3.834 1.172 1.097.303 2.346.04 3.046-.395.742-.461 9.305-6.191 9.92-6.693.614-.502 1.104.141.602.644-.502.502-6.38 6.207-7.155 6.997-.941.959-.273 1.953.358 2.351.721.454 5.906 3.932 6.687 4.49.781.558 1.573.811 2.298.811.725 0 1.107-.955 1.468-2.064z" },
    { href: "https://www.facebook.com/movis.ng", label: "Facebook", icon: "M25 3C12.85 3 3 12.85 3 25c0 11.03 8.125 20.137 18.712 21.728V30.831h-5.443v-5.783h5.443v-3.848c0-6.371 3.104-9.168 8.399-9.168 2.536 0 3.877.188 4.512.274v5.048h-3.612c-2.248 0-3.033 2.131-3.033 4.533v3.161h6.588l-.894 5.783h-5.694v15.944C38.716 45.318 47 36.137 47 25c0-12.15-9.85-22-22-22z" },
  ];

  return (
    <footer className="footer footer-center p-6 bg-primaryColor text-base-content rounded">
      <nav className="grid grid-flow-col gap-4 md:gap-4 flex-wrap justify-center">
        {navLinks.map((link) => (
          <a key={link.href} className="link link-hover" href={link.href}>
            {link.label}
          </a>
        ))}
      </nav>
      <nav>
        <div className="grid grid-flow-col gap-4">
          {socialLinks.map((link) => (
            <a
              key={link.href}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-ghost btn-square"
              aria-label={link.label}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 50 50"
                className="fill-current"
              >
                <path d={link.icon} />
              </svg>
            </a>
          ))}
        </div>
      </nav>
      <aside>
        <p>&copy; {new Date().getFullYear()} Movis.ng. All rights reserved.</p>
      </aside>
    </footer>
  );
};

export default Footer;