import React, { useState } from "react";
import { PlusCircle, Search, Menu } from "react-feather";
import logo from "../../assets/images/movis_logo.png";
import { useIsLoggedIn } from "../components/isLoggedIn";
import AddMovieModal from "./AddMovieModal";

function Navbar() {
  const isLoggedIn = useIsLoggedIn();
  const [openForm, setOpenForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      window.location.href = `/search?q=${encodeURIComponent(searchQuery.trim())}`;
    }
  };

  const navItems = [
    { href: "/", label: "Home" },
    { href: "/movies", label: "Movies" },
    { href: "/series", label: "TV Series" },
  ];

  return (
    <nav className="bg-darkColor shadow-md">
      {openForm && (
        <AddMovieModal
          openModal={openForm}
          closeModal={() => setOpenForm(false)}
        />
      )}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="flex-shrink-0">
              <img src={logo} alt="Movis Logo" className="h-8 w-auto" />
            </a>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className="text-white hover:text-primaryColor px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <form onSubmit={handleSearch} className="mr-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="input input-bordered w-full max-w-xs text-darkColor placeholder-gray-400"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  type="submit"
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
                >
                  <Search size={20} />
                </button>
              </div>
            </form>
            {isLoggedIn && (
              <button
                onClick={() => setOpenForm(true)}
              >
                <PlusCircle size={25} color="white" />
              </button>
            )}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="btn btn-ghost btn-circle"
              >
                <Menu size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <a
                key={item.href}
                href={item.href}
                className="text-white hover:text-primaryColor block px-3 py-2 rounded-md text-base font-medium"
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;