import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Lazy load components
const Home = lazy(() => import('./views/pages/Home'));
const MovieDetails = lazy(() => import('./views/pages/MovieDetails'));
const SearchPage = lazy(() => import('./views/pages/Search'));
const Login = lazy(() => import('./views/components/login'));
const Movies = lazy(() => import('./views/pages/Movies'));
const Genres = lazy(() => import('./views/pages/Genres'));
const Series = lazy(() => import('./views/pages/Series'));
const SeriesDetails = lazy(() => import('./views/pages/SeriesDetails'));
const Error404 = lazy(() => import('./views/pages/Error404'));
const KDrama = lazy(() => import('./views/pages/K-Drama'));
const Bollywood = lazy(() => import('./views/pages/Bollywood'));
const Contact = lazy(() => import('./views/pages/Contact'));
const DCMA = lazy(() => import('./views/pages/DCMA'));

// Loading component for Suspense fallback
const Loading = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="loading loading-spinner loading-lg"></div>
  </div>
);

const AppRouter = () => (
  <Router>
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/movies" element={<Movies />} />
        <Route path="/k-drama" element={<KDrama />} />
        <Route path="/bollywood" element={<Bollywood />} />
        <Route path="/series" element={<Series />} />
        <Route path="/genres" element={<Genres />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/movie/:movieId/:name" element={<MovieDetails />} />
        <Route path="/series/:seriesId/:name" element={<SeriesDetails />} />
        <Route path="/series/:seriesId/:seasonId" element={<SeriesDetails />} />
        <Route path="/enter" element={<Login />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dcma" element={<DCMA />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  </Router>
);

export default AppRouter;